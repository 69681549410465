import React, { useRef, useEffect, useId, useState } from 'react'
import {
  DotLottieCommonPlayer,
  DotLottiePlayer,
  PlayerEvents,
  type Props as DotLottieProps,
} from '@dotlottie/react-player'

import { useIntersectionObserver } from 'usehooks-ts'
import { cx } from '@/utils/strings'
import type { TypeComponentLottie } from '@/types/ctf'
import usePrevious from '@/utils/usePrevious'

type LottieEntry = TypeComponentLottie<'WITHOUT_UNRESOLVABLE_LINKS'>

export type LottieProps = Partial<Omit<LottieEntry['fields'], 'autoplayType' | 'lottieFile'>> & {
  className?: string
  lottieFile?: {
    fields?: {
      file: {
        url: string
      }
    }
    file?: {
      url: string
    }
  }
  animationData?: DotLottieProps['src']
  /**
   * Only show a specific frame. For reduced motion.
   */
  onlyShowFrame?: number | 'last'
  entryId?: string
  onEvent?: (event: PlayerEvents) => void
  playerRef?: React.RefObject<DotLottieCommonPlayer>
  activeAnimationId?: string
  autoplayType?: LottieEntry['fields']['autoplayType'] | 'visible' | 'hover' | 'click' | 'loop' | 'none'
}

export default function Lottie({ ...fields }: LottieProps) {
  const {
    className,
    animationData,
    lottieFile,
    autoplayType: _autoplayType = 'loop',
    onlyShowFrame,
    onEvent,
    playerRef,
    activeAnimationId,
  } = fields

  const id = useId()
  const lottieId = `lottie-${id.replace(/:/g, '')}`

  const ref = useRef<DotLottieCommonPlayer>(null)
  const lottieRef = playerRef || ref
  const [isLoaded, setIsLoaded] = useState(false)
  const [playQueued, setPlayQueued] = useState(false)

  const src = animationData || lottieFile?.fields?.file?.url || lottieFile?.file?.url
  const prevSrc = usePrevious(src)

  const { entry, ref: containerRef } = useIntersectionObserver({ threshold: 0.5, freezeOnceVisible: true })
  const isVisible = !!entry?.isIntersecting

  const autoplayType = _autoplayType.toLowerCase()
  const autoplay = autoplayType !== 'none' && (autoplayType === 'visible' || autoplayType === 'loop')
  const loop = autoplayType === 'loop'

  const play = () => {
    if (typeof onlyShowFrame === 'number') {
      lottieRef.current?.playSegments([onlyShowFrame, onlyShowFrame], true)
    } else if (onlyShowFrame === 'last') {
      const duration = lottieRef.current?.totalFrames || 0
      lottieRef.current?.seek(duration)
    } else {
      lottieRef.current?.play()
    }
  }

  useEffect(() => {
    if (src && src !== prevSrc) {
      lottieRef.current?.stop()
      setIsLoaded(false)
      if (autoplay && isVisible) {
        setPlayQueued(true)
      }
    }
  }, [src])

  const queuePlay = () => {
    if (isLoaded) {
      play()
    } else {
      setPlayQueued(true)
    }
  }

  useEffect(() => {
    if (playQueued && isLoaded) {
      play()
      setPlayQueued(false)
    }
  }, [playQueued, isLoaded])

  useEffect(() => {
    if (autoplay && isVisible) {
      queuePlay()
    }
  }, [autoplay, isVisible])

  if (!src) return null
  return (
    <div
      ref={containerRef}
      onClick={autoplayType === 'click' ? queuePlay : undefined}
      onKeyUp={
        autoplayType === 'click'
          ? (e) => {
              if (e.key === 'Enter') queuePlay()
            }
          : undefined
      }
      className={cx(className, autoplayType === 'click' && 'cursor-pointer')}
    >
      <DotLottiePlayer
        id={lottieId}
        ref={lottieRef}
        src={src}
        autoplay={false}
        loop={loop}
        hover={autoplayType === 'hover'}
        style={{ width: '100%', height: '100%' }}
        activeAnimationId={activeAnimationId}
        onEvent={(event) => {
          switch (event) {
            case PlayerEvents.Ready:
              setIsLoaded(true)
              break
            default:
          }
          onEvent?.(event)
        }}
      />
    </div>
  )
}
